const data = [{
    id : 1,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2022, 2023],
    question: "exam데이터1",
    text: "지문1",
    image: "",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 1
},{
    id : 2,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터2",
    text: "지문2",
    image: "이미지2",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
},{
    id : 3,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2020],
    question: "데이터3",
    text: "지문3",
    image: "이미지3",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}
,{
    id : 4,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터4",
    text: "지문4",
    image: "이미지4",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}
,{
    id : 5,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터5",
    text: "지문5",
    image: "이미지5",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}
,{
    id : 6,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터6",
    text: "지문6",
    image: "이미지6",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}
,{
    id : 7,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터7",
    text: "지문7",
    image: "이미지7",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}
,{
    id : 8,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터8",
    text: "지문8",
    image: "이미지8",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}
,{
    id : 9,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터9",
    text: "지문9",
    image: "이미지9",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}
,{
    id : 10,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "데이터10",
    text: "지문10",
    image: "이미지10",
    option1: "답1",
    option2: "답2",
    option3: "답3",
    option4: "답4",
    option5: "답5",
    answer: 3
}]

export default data