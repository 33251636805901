import React, { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Header from './header'
import Footer from './footer'
import Solve from './solve'
import Exam from './exam'
import Test from './test'
import Main from './main'
import Info from './info'
import User from './user'
import PreConfirm from './pre_confirm'
import Confirm from './confirm'
import examData from '../data/exam_data'
import testData from '../data/test_data'

import useStore from './store';
import styled from 'styled-components'

const Index = () => {
    const { isvisible, setisvisible } = useStore();
    const setDataType = useStore((state) => state.setDataType);

    const location = useLocation();
    const path = location.pathname;
    const lastPathComponent = path.substring(path.lastIndexOf('/') + 1);

    useEffect(() => {
        const choiceData = () => {
            if (lastPathComponent == 'test') {
                return 'test'
            } else if (lastPathComponent == 'exam') {
                return 'exam'
            }
        }
        const result = choiceData()
        setDataType(result)
    }, [lastPathComponent, setDataType])

    const data = Array.from({ length: 88 }, (_, index) => ({ [index + 1]: 0 }));
    
    const [sheet, setSheet] = useState(data)
    const [viewcount, setviewcount] = useState(1)

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 830) {
            setisvisible(true);
            document.title = "요양보호사 모의시험 모바일화면"; // 원하는 타이틀로 변경합니다.
          } else {
            setisvisible(false);
            document.title = "요양보호사 모의시험"
          } 
        };
    
        handleResize(); // 초기화 할 때 한 번 호출하여 현재 크기에 따라 isvisible 값을 설정
        window.addEventListener('resize', handleResize); // 화면 크기 변경 감지하여 isvisible 값 변경
    
        return () => {
          window.removeEventListener('resize', handleResize); // 컴포넌트가 언마운트 될 때 이벤트 리스너 제거
        };
      }, []);

    const NotFound = () => {
        return <div>잘못된 경로입니다.</div>
      }
    
  return (
    <Wrap>
        <Wrap>
            <Header />
            <Body>
                <GridContainer isvisible={isvisible}>
                    <LeftPanel>
                        <Routes>
                            <Route path='/' element={<Main />} />
                            <Route path='/info' element={<Info />} />
                            <Route path='/exam' element={<Exam /> } />
                            <Route path='/test' element={<Test /> } />
                            <Route path='/user' element={<User /> } />
                            <Route path='/pre_confirm' element={<PreConfirm /> } />
                            <Route path='/confirm' element={<Confirm />} />
                            {/* <Route path='/todos' element={<Todos />} /> */}
                            {/* <Route path='/todos/:id' element={<Todo />} /> */}
                            <Route path='/*' element={<NotFound />} />
                        </Routes>
                    </LeftPanel>
                    <RightPanel>
                        <Solve />
                    </RightPanel>
                </GridContainer>
            </Body>
            <Footer />
        </Wrap>
    </Wrap>
  )
}

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
`;

const Box = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #13264E;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    transition: transform 0.3s ease;
    transform: ${props => props.isvisible ? 'scale(1)' : 'scale(0)'};
`;

const Body = styled.div`
    display: flex;
    width: 100%;
    display: inline-block;
`

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => props.isvisible ? '1fr 0.5fr' : '1fr 300px' };
    transition: transform 0.3s ease;
    height: calc(85vh - 4vh); /* 헤더 10vh, 푸터 5vh를 제외한 나머지 전체 높이 */
    width: 100%;
`;

const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 1vh;
`;

const RightPanel = styled.div`
    overflow-y: auto;
    padding: 1vh;
    background: #13264E;
    color: #FFF;
`;

export default Index