const data = [{
    id : 1,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2022, 2023],
    question: "다음 중 애국가 1절 가사로 올바른 것은 무엇입니까?",
    text: "",
    image: "",
    option1: "동해물과 백두산이 마르고 닳도록",
    option2: "이 기상과 이 맘으로 충성을 다하여",
    option3: "가을 하늘 공활한데 높고 구름 없이",
    option4: "남산 위에 저 소나무 철갑을 두른 듯",
    option5: "바람 서리 불변함은 우리 기상일세",
    answer: 1
},{
    id : 2,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "대한민국 국화로 올바른 것은 무엇입니까?",
    text: "",
    image: "",
    option1: "개나리",
    option2: "진달래",
    option3: "무궁화",
    option4: "나팔꽃",
    option5: "연꽃",
    answer: 3
},{
    id : 3,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2020],
    question: "대한민국의 수도는 어느 곳입니까?",
    text: "",
    image: "",
    option1: "서울",
    option2: "대전",
    option3: "대구",
    option4: "부산",
    option5: "광주",
    answer: 1
},{
    id : 4,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "다음 인물의 이름은 무엇입니까?",
    text: "",
    image: "https://gwanghwamun.seoul.go.kr/resources/client/images/contents/img_introduce_2.jpg",
    option1: "장보고",
    option2: "강감찬",
    option3: "서희",
    option4: "장영실",
    option5: "이순신",
    answer: 5
},{
    id : 5,
    sub1: 1,
    sub2: 2,
    sub3: 3,
    year: [2021, 2023],
    question: "아래 지문을 읽고 연상되는 동물은 무엇입니까?",
    text: "야옹야옹",
    image: "",
    option1: "개",
    option2: "말",
    option3: "닭",
    option4: "고양이",
    option5: "용",
    answer: 4
},{
    id : 6,
    sub1: 1,
    sub2: 1,
    sub3: 3,
    year: [2021, 2023],
    question: "다음 중 가장 바르게 나열한 것은 무엇입니까?",
    text: "",
    image: "",
    option1: "남 - 동 - 서 - 북",
    option2: "동 - 서 - 남 - 북",
    option3: "동 - 남 - 북 - 서",
    option4: "서 - 동 - 북 - 남",
    option5: "북 - 남 - 서 - 동",
    answer: 2
},
]

export default data